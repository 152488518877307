<template>
  <div class="">
    <PreLoader v-if="isLoading" />

    <form v-else autocomplete="off">
      <!-- StudentsFollowUpSettingsTable -->
      <div class="">
        <b-button
          v-b-toggle.StudentsFollowUpSettingsTable
          class="btn btn-lg btn-collapse no-margin"
        >
          {{ $t("students.modelName") }}
          <i class="fa fa-angle-down" aria-hidden="true"></i>
        </b-button>
        <b-collapse visible id="StudentsFollowUpSettingsTable">
          <div class="my-card no-margin">
            <StudentsFollowUpSettingsTable
              :followUpSetting="followUpSetting"
              :userStudentOptions="userStudentOptions"
              :defaultImg="defaultImgStudents"
            />
          </div>
        </b-collapse>
      </div>

      <!-- EducationalCategoriesFollowUpSettingsTable -->
      <div class="">
        <b-button
          v-b-toggle.EducationalCategoriesFollowUpSettingsTable
          class="btn btn-lg btn-collapse no-margin"
        >
          {{ $t("EducationalCategories.modelName") }}
          <i class="fa fa-angle-down" aria-hidden="true"></i>
        </b-button>
        <b-collapse visible id="EducationalCategoriesFollowUpSettingsTable">
          <div class="my-card no-margin">
            <EducationalCategoriesFollowUpSettingsTable
              :followUpSetting="followUpSetting"
              :educationalCategoryTokenOptions="educationalCategoryTokenOptions"
              :defaultImg="defaultImgStudents"
            />
          </div>
        </b-collapse>
      </div>

      <div class="form-actions">
        <div class="icon-submit" @click.prevent="submitForm">
          <img src="@/assets/images/check-icon.svg" :title="submitName" />
        </div>
        <div
          @click.prevent
          class="icon-cancel"
          v-b-modal="`ConfirmCloseSheet-${bottomSheetName}`"
        >
          <img src="@/assets/images/cancel-icon.svg" :title="$t('cancel')" />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import PreLoader from "./../../../../components/general/PreLoader.vue";
import StudentsFollowUpSettingsTable from "./StudentsFollowUpSettingsTable.vue";
import EducationalCategoriesFollowUpSettingsTable from "./EducationalCategoriesFollowUpSettingsTable.vue";
import defaultImgStudents from "@/assets/images/students.svg";
import defaultImgEducationalCategories from "@/assets/images/EducationalCategories.svg";

import {
  checkPrivilege,
  fullPathFileFromServer,
} from "./../../../../utils/functions";
import generalMixin from "./../../../../utils/generalMixin";

export default {
  mixins: [generalMixin],
  components: {
    PreLoader,
    StudentsFollowUpSettingsTable,
    EducationalCategoriesFollowUpSettingsTable,
  },
  props: {
    followUpSetting: { type: Object },
    userStudentOptions: {
      type: Array,
      default: () => [],
    },
    educationalCategoryTokenOptions: {
      type: Array,
      default: () => [],
    },
    submitName: { type: String },
    id: { type: String, default: "id" },
    bottomSheetName: { type: String, default: "" },
  },
  data() {
    return {
      defaultImgStudents,
      defaultImgEducationalCategories,
    };
  },
  computed: {},
  watch: {},
  created() {},
  methods: {
    async submitForm() {
      this.$emit("submitForm");
    },
    checkPrivilege,
    fullPathFileFromServer,
  },
};
</script>
