<template>
  <div v-if="userSalesWithStudentsSettingsData.length" class="table-container">
    <table class="my-table">
      <thead>
        <tr>
          <th rowspan="2">#</th>
          <th colspan="4">{{ $t("students.data") }}</th>
        </tr>
        <tr>
          <th>{{ $t("general.image") }}</th>
          <th>{{ $t("general.code") }}</th>
          <th class="cell-lg">{{ $t("general.name") }}</th>
          <th>{{ $t("Users.phone") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(userStudent, index) in userSalesWithStudentsSettingsData"
          :key="userStudent.userStudentToken"
        >
          <td>{{ ++index }}</td>
          <td>
            <img
              class="item-img-table"
              :src="
                fullPathFileFromServer(
                  userStudent.userStudentInfoData.userImagePath,
                  defaultImg
                )
              "
              :onerror="`this.src='${defaultImg}'`"
            />
          </td>
          <td>{{ isDataExist(userStudent.userStudentInfoData.fullCode) }}</td>
          <td>
            {{ isDataExist(userStudent.userStudentInfoData.userNameCurrent) }}
          </td>
          <td class="table-phone-number">
            {{ isDataExist(userStudent.userStudentInfoData.userPhoneWithCC) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {
  isDataExist,
  checkPrivilege,
  fullPathFileFromServer,
} from "./../../../../utils/functions";

export default {
  name: "UserSalesWithStudentsSettingsTable",
  mixins: [],
  components: {},
  props: {
    userSalesWithStudentsSettingsData: {
      type: Array,
      default: () => [],
    },
    defaultImg: {
      type: String,
    },
  },

  data() {
    return {};
  },
  computed: {},
  methods: {
    isDataExist,
    checkPrivilege,
    fullPathFileFromServer,
  },
  async created() {},
};
</script>
