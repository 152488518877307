<template>
  <div class="table-container">
    <template v-if="usersData.length">
      <table class="my-table">
        <thead>
          <tr>
            <th rowspan="2">#</th>
            <th colspan="4">{{ $t("Users.data") }}</th>
            <th colspan="2">{{ $t("UserInterests.followUp.total") }}</th>
            <th rowspan="2"><i class="fas fa-sliders-h"></i></th>
          </tr>
          <tr>
            <th>{{ $t("general.image") }}</th>
            <th>{{ $t("general.code") }}</th>
            <th class="cell-lg">{{ $t("general.name") }}</th>
            <th>{{ $t("Users.phone") }}</th>
            <th>{{ $t("EducationalCategories.modelName") }}</th>
            <th>{{ $t("students.modelName") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(user, index) in usersData" :key="user.userToken">
            <td>{{ ++index + filterData.currentIndex }}</td>
            <td>
              <img
                class="item-img-table"
                :src="fullPathFileFromServer(user.userImagePath, defaultImg)"
                :onerror="`this.src='${defaultImg}'`"
              />
            </td>
            <td>{{ isDataExist(user.fullCode) }}</td>
            <td>
              {{ isDataExist(user.userNameCurrent) }}
            </td>
            <td class="table-phone-number">
              {{ isDataExist(user.userPhoneWithCC) }}
            </td>
            <td>
              {{
                isDataExist(
                  user.userFollowUpSettingStatisticData
                    ? user.userFollowUpSettingStatisticData
                        .countEducationalCategories
                    : ""
                )
              }}
            </td>
            <td>
              {{
                isDataExist(
                  user.userFollowUpSettingStatisticData
                    ? user.userFollowUpSettingStatisticData.countStudents
                    : ""
                )
              }}
            </td>
            <td class="fmenu-item-container">
              <FloatingMenu>
                <li v-if="checkPrivilege(hasFollowUpSetting())">
                  <router-link
                    :to="{
                      name: 'FollowUpSettings',
                      params: {
                        token: user.userToken,
                      },
                    }"
                    :title="$t('FollowUpSettings.modelName')"
                  >
                    <img src="@/assets/images/followUpSettings.svg" />
                  </router-link>
                </li>
              </FloatingMenu>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
    <template v-else-if="userSalesInfo">
      <table class="my-table">
        <thead>
          <tr>
            <th colspan="4">{{ $t("sales.data") }}</th>
            <th colspan="2">{{ $t("UserInterests.followUp.total") }}</th>
          </tr>
          <tr>
            <th>{{ $t("general.image") }}</th>
            <th>{{ $t("general.code") }}</th>
            <th class="cell-lg">{{ $t("general.name") }}</th>
            <th>{{ $t("Users.phone") }}</th>
            <th>{{ $t("EducationalCategories.modelName") }}</th>
            <th>{{ $t("students.modelName") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <img
                class="item-img-table"
                :src="
                  fullPathFileFromServer(
                    userSalesInfo.userImagePath,
                    defaultImg
                  )
                "
                :onerror="`this.src='${defaultImg}'`"
              />
            </td>
            <td>{{ isDataExist(userSalesInfo.fullCode) }}</td>
            <td>
              {{ isDataExist(userSalesInfo.userNameCurrent) }}
            </td>
            <td class="table-phone-number">
              {{ isDataExist(userSalesInfo.userPhoneWithCC) }}
            </td>
            <td>
              {{ isDataExist(userSalesInfo.countEducationalCategories) }}
            </td>
            <td>
              {{ isDataExist(userSalesInfo.countStudents) }}
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </div>
</template>

<script>
import {
  isDataExist,
  checkPrivilege,
  fullPathFileFromServer,
} from "./../../../../utils/functions";
import { hasFollowUpSetting } from "./../../../../utils/privilegeHelper";
import generalMixin from "./../../../../utils/generalMixin";
import FloatingMenu from "./../../../../components/general/FloatingMenu.vue";

export default {
  name: "UserSalesTable",
  mixins: [generalMixin],
  components: { FloatingMenu },
  props: {
    usersData: {
      type: Array,
      default: () => [],
    },
    userSalesInfo: {
      type: Object,
    },
    filterData: {
      type: Object,
    },
    defaultImg: {
      type: String,
    },
  },

  data() {
    return {};
  },
  computed: {},
  methods: {
    isDataExist,
    checkPrivilege,
    fullPathFileFromServer,
    hasFollowUpSetting,
  },
  async created() {},
};
</script>
